<template>
    <Nav :background="bg" />
    <main class="about-main">
        <div class="about-header">
            <h1>About us</h1>
        </div>

        <div class="about-section">
            <div class="content">
                <h2>About SQNTT</h2>
                <p>SQNTT is a leading travel and tourism company specializing in international education consulting, travel
                    services, and immigration solutions. Established in 2024, headquartered in LAGOS STATE, NIGERIA. We
                    craft unforgettable journeys tailored to your style, schedule, and budget. Our experts cover various
                    destinations and travel types, ensuring seamless and stress-free experiences. From relaxation to
                    adventure, cultural immersion, and more, we prioritize your unique preferences. Let us handle the
                    details so you can focus on making lifelong memories.</p>

                <h2>What we do</h2>
                <div class="section">
                    <div>
                        <span class="label">
                            International Education Consulting
                        </span>
                        <ul>
                            <li>
                                - University selection
                            </li>
                            <li>
                               - Admission processing
                            </li>
                            <li>
                                - Scholarship assistance
                            </li>
                            <li>
                                - Visa application and guidance
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span class="label">
                            Travel Services
                        </span>
                        <ul>
                            <li>
                                - Flight bookings
                            </li>
                            <li>
                                - Hotel reservations
                            </li>
                            <li>
                                - Tour packages
                            </li>
                            <li>
                                - Travel insurance
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span class="label">
                            Immigration Solutions
                        </span>
                        <ul>
                            <li>
                                - Permanent residency
                            </li>
                            <li>
                                - Work permits
                            </li>
                            <li>
                                - Family sponsorships
                            </li>
                            <li>
                                - Business immigration
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <img :src="require('../assets/about-shot.jpg')" alt="">
        </div>

        <div class="vision-section">
            <h2>
                Our Motto
            </h2>
            <p>
                Your success is our priority.
            </p>
            <h2>
                Our Mission Statement
            </h2>
            <p>
                "At SQNTT, our mission is to deliver exceptional travel experiences that exceed our clients' expectations.
                We craft personalized journeys, provide expert guidance, and ensure seamless travel solutions. Through our
                passion for exploration and commitment to excellence, we strive to build lasting relationships and make
                every trip unforgettable."
            </p>

            <h2>
                Our Vision Statement
            </h2>
            <p>
                "To be the trusted travel companion for discerning explorers, SQNTT envisions a world where travel inspires
                wonder, fosters connections, and enriches lives. We aim to innovate, sustain, and lead the travel industry,
                while consistently delighting our clients."
            </p>
        </div>

        <div class="values-section">
            <div class="content">
                <h2>
                    Our Core Values
                </h2>
                <ul>
                    <li>
                        <i class="pi pi-check"></i>
                        Personalized Travel Experiences
                    </li>
                    <li>
                        <i class="pi pi-check"></i>
                        Exceptional Service
                    </li>
                    <li>
                        <i class="pi pi-check"></i>
                        Expert Knowledge
                    </li>
                    <li>
                        <i class="pi pi-check"></i>
                        Sustainability
                    </li>
                    <li>
                        <i class="pi pi-check"></i>
                        Customer Delight
                    </li>
                    <li>
                        <i class="pi pi-check"></i>
                        Innovation
                    </li>
                    <li>
                        <i class="pi pi-check"></i>
                        Collaboration
                    </li>
                </ul>
            </div>
            <img :src="require('../assets/values.jpg')" alt="">
        </div>

        <div class="why-section">
            <img :src="require('../assets/why.jpg')" alt="">
            <div class="content">
                <h2>Why choose SQNTT?</h2>
                <p>
                    Our team possesses expert knowledge of international education and immigration laws, ensuring accurate
                    guidance.
                    We provide personalized counseling to address individual needs and aspirations.
                    With a proven track record of success, our clients achieve their goals efficiently.
                    Our competitive fees offer exceptional value without compromising quality.
                    Through strong partnerships with top universities and institutions worldwide, we facilitate seamless
                    transitions to global educational opportunities.

                </p>
            </div>
        </div>

        <div class="commitment-section">
            <div class="content">
                <h2>Our Commitment To Excellence</h2>
                <p>
                    At SQNTT, excellence is our guiding principle in international education and immigration consulting. We
                    passionately empower individuals and families to achieve global aspirations through expert guidance and
                    personalized solutions. Our commitment is rooted in unwavering passion, knowledge, transparency, and
                    strategic partnerships. We pledge to deliver precision, speed, and exceptional service, exceeding client
                    expectations. SQNTT values excellence, integrity, empathy, innovation, and collaboration.
                </p>
            </div>
            <img :src="require('../assets/commitment.jpg')" alt="">
        </div>

        <div class="team__section">
            <h2>
                Our Team
            </h2>

            <p>
                Meet our experienced and dedicated team of travel <br> and education professionals, committed to delivering
                exceptional service and support.
            </p>

            <div class="members">
                <div class="member" v-for="member in members" :key="member">
                    <img :src="require(`../assets/${member.img}`)" alt="Team member">
                    <h3>{{ member.name }}</h3>
                    <p>{{ member.role }}</p>
                </div>
            </div>
        </div>
    </main>
    <Footer />
</template>

<script setup>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { ref, onMounted, onUnmounted } from 'vue'

const bg = ref("#45a5d3"),
    windowWidth = ref(window.innerWidth)

const members = ref([
    {
        name: 'Linus Comfort',
        role: 'CEO',
        img: 'ceo.jpg'
    },
    {
        name: 'Comrade Emmanuel Mokwunye Onochie',
        role: 'Director of finance',
        img: 'dof.jpg'
    },
    {
        name: 'Richmond Ekene Egwim',
        role: 'Recruitment officer',
        img: 'pro.jpg'
    },
    {
        name: 'Gotau Isreal',
        role: 'Software Engineer',
        img: 'soe.jpg'
    },
    {
        name: 'Queen Nenrit',
        role: 'Customer Service officer',
        img: 'cso.jpg'
    },
])

onMounted(() => {
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth

        if (windowWidth.value < 630) {
            bg.value = 'transparent'
        } else {
            bg.value = '#45a5d3'
        }
    })
})

onUnmounted(() => {
    window.removeEventListener('resize', () => { })
})
</script>

<style lang="scss">
.about-main {
    @include flex(column, center, center, 2.25em, nowrap);
    margin-block: 30px;

    .about-header {
        width: 95%;
        padding: 20px;
        background-color: $color-primary;
        border-radius: 8px;

        h1 {
            font-size: var(--font-size-fluid-3);
            color: $text-muted;
        }
    }

    .about-section {
        width: 80%;
        @include flex(row, flex-start, space-between, 0, wrap);

        .content {
            width: 50%;
            @include flex(column, flex-start, flex-start, 1em, nowrap);

            h2 {
                font-size: 28px;
            }

            p {
                line-height: var(--font-lineheight-4);
            }

            .section {
                width: 100%;
                @include flex(row, flex-start, flex-start, 1em, wrap);

                div {
                    width: calc(50% - 1em);
                    padding: 8px;
                    background-color: #eee;
                    border-radius: 2px;
                    box-shadow: var(--shadow-1);
                    border-left: solid var(--border-size-2) black;

                    .label {
                        display: inline-block;
                        color: orangered;
                        font-weight: bold;
                        font-size: var(--font-size-2);
                        margin-bottom: 8px;
                    }

                    ul {
                        @include flex(column, flex-start, flex-start, .5em, nowrap);
                    }

                }
            }
        }

        img {
            width: 40%;
            height: 400px;
            object-fit: cover;
            border-radius: var(--radius-2);
        }
    }

    .values-section {
        width: 80%;
        @include flex(row, center, space-between, 0, wrap);

        .content {
            @include flex(column, flex-start, flex-start, .5em, nowrap);

            h2 {
                font-size: 28px;
            }

            ul {
                @include flex(column, flex-start, flex-start, .5em, nowrap);

                li {
                    display: flex;
                    align-items: center;
                    gap: .25em;
                    font-size: 16px;

                    i {
                        padding: 4px;
                        background-color: gold;
                        border-radius: var(--radius-round);
                    }
                }
            }

        }

        img {
            width: 50%;
            height: 400px;
            object-fit: cover;
            border-radius: var(--radius-2);
        }
    }

    .why-section,
    .commitment-section {
        width: 80%;
        @include flex(row, center, space-between, 0, wrap);
        background-color: $color-primary-lighter;
        box-shadow: var(--shadow-1);
        padding: 12px;

        .content {
            width: 50%;
            @include flex(column, flex-start, flex-start, .5em, nowrap);

            h2 {
                font-size: 28px;
            }

            ul {
                @include flex(column, flex-start, flex-start, .5em, nowrap);

                li {
                    display: flex;
                    align-items: center;
                    gap: .25em;
                    font-size: 16px;

                    i {
                        padding: 4px;
                        background-color: gold;
                        border-radius: var(--radius-round);
                    }
                }
            }

        }

        img {
            width: 40%;
            height: 400px;
            object-fit: cover;
            border-radius: var(--radius-2);
        }
    }

    .vision-section {
        width: 80%;
        @include flex(column, flex-start, center, .5em, nowrap);
        background-color: $color-primary-dark;
        box-shadow: var(--shadow-1);
        padding: 20px;
        border-radius: var(--radius-2);

        h2 {
            font-size: 28px;
            text-decoration: underline;
            color: white;
            color: gold;
        }

        p {
            line-height: var(--font-lineheight-4);
            font-weight: 400;
            color: white;
        }
    }

    .team__section {
        @include flex(column, center, center, 1em, nowrap);
        width: 80%;

        h2 {
            font-size: 33px;
        }

        p {
            text-align: center;
        }

        .members {
            width: 100%;
            @include flex(row, center, center, 1em, wrap);

            .member {
                @include flex(column, center, center, .3em, nowrap);
                width: calc(33.33% - 2em);

                img {
                    width: 50%;
                    height: auto;
                    border-radius: var(--radius-round);
                }

                h3 {
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 880px) {
    .about-main {
        gap: 1em;
        margin-block: 20px;

        .about-section,
        .values-section,
        .why-section,
        .commitment-section {
            width: 90%;
            gap: 1em;

            img {
                width: 100%;
            }

            .content {
                width: 100%;
            }
        }

        .why-section,
        .commitment-section {
            width: 100%;

            img {
                height: 250px;
            }
        }

        .vision-section {
            width: 90%;
        }

        .team__section {
            width: 100%;
            padding: 18px;
        }
    }
}

@media (max-width: 720px) {
    .about-main {
        .team__section {
            .members {
                .member {
                    width: calc(100% - 2em);

                    img {
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media (max-width: 665px) {
    .about-main {
        .about-section {
            .content {
                .section {
                    div {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>