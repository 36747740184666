<template>
    <Nav />
    <main class="country-main">
        <img v-if="countryInfo" :src="require(`../assets/${countryInfo.name}.jpg`)" alt="">
        <div class="content-container" v-if="countryInfo">
            <!-- <div class="section">
                <h1>
                    Why {{ countryInfo.country }}?
                </h1>
                <p>
                    {{ countryInfo.why }}
                </p>
            </div>

            <div class="section">
                <h2>
                    System of Education
                </h2>
                <p>
                    {{ countryInfo.systemOfEducation }}
                </p>
            </div>

            <div class="section">
                <h2>
                    Average tuition costs
                </h2>
                <p>
                    {{ countryInfo.averageTuitionCosts }}
                </p>
            </div>

            <div class="section">
                <h2>
                    Here are some facts about {{ countryInfo.country }}
                </h2>

                <ul>
                    <li v-for="(fact, index) in countryInfo.facts" :key="fact">
                        <b>{{ index + 1 }}.</b> {{ fact }}
                    </li>
                </ul>
            </div>

            <div class="section">
                <h2>Your immigration options</h2>

                <ul class="second-list">
                    <li v-for="option in countryInfo.immigrationOptions" :key="option">
                        <h3>
                            {{ option.type }}
                        </h3>
                        <p>
                            - {{ option.description }}
                        </p>
                    </li>
                </ul>
            </div> -->
            <section v-for="detail in countryInfo.details">
                <h2>
                    {{ detail.section }}
                </h2>

                <ul>
                    <li v-for="content in detail.content">
                        {{ content }}
                    </li>
                </ul>
            </section>
        </div>

    </main>

    <Footer />
</template>

<script setup>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { countriesInfo } from '@/data/countries'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const countryInfo = ref(null),
    route = useRoute()

onMounted(() => {
    console.log(countriesInfo)

    countriesInfo.forEach(country => {
        if (country.id === route.params.country) {
            countryInfo.value = country
        }
    })

    console.log(countryInfo.value)
})
</script>

<style lang="scss">
.country-main {
    @include flex(column, center, flex-start, 1em, wrap);

    .content-container {
        width: 80%;

        section {
            @include flex(column, flex-start, flex-start, .5em, nowrap);

            h2 {
                font-size: var(--font-size-5);
                text-decoration: underline;
            }

            ul {
                @include flex(column, flex-start, flex-start, .5em, nowrap);

                li {
                    font-size: var(--font-size-2);
                    list-style-type: disc;
                }
            }
        }
    }

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .country-main {
        .content-container {
            width: 100%;
        }
    }
}
</style>