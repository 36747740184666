<template>
    <header class="header" :style="{
        position: position
    }">
        <nav class="nav desktop__nav">
            <router-link to="/" class="home-logo">
                <img :src="require('@/assets/SQNTT LOGO .png')" alt="site logo">
            </router-link>

            <div class="nav__links" :class="{ active: active, offset: position === 'absolute' }">
                <ul>
                    <li><router-link :class="{ contrastColor: position === 'static' }" to="/about">About us</router-link>
                    </li>
                    <li><router-link :class="{ contrastColor: position === 'static' }" to="/services">Our Services</router-link>
                    </li>
                    <li><router-link :class="{ contrastColor: position === 'static' }" to="/coverage">Coverage</router-link>
                    </li>
                    <li><a href="https://forms.gle/oiQsWuRMut18HTdi7" :class="{ contrastColor: position === 'static' }" target="_blank">Become an Agent</a>
                    </li>
                    <li><a href="https://forms.gle/F2LbF2GrY8bmKiMq5" target="_blank" class="cta">Apply</a></li>
                </ul>
            </div>

            <div class="menu-bars" @click="showHideMenu" :class="{ active: active }">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
        </nav>
    </header>
</template>

<script setup>
import { ref } from 'vue'

const active = ref(false)

const showHideMenu = () => {
    active.value = !active.value
}

const props = defineProps({
    position: {
        type: String,
        required: false,
        default: 'static'
    }
})

</script>

<style lang="scss">
.header {
    width: calc(100dvw - ($page-padding--left / 2) - ($page-padding--right / 2));
    max-width: 1400px;
    margin-inline: auto;
    right: 0;
    left: 0;
    margin-inline: auto;
    z-index: 999;
    border-radius: var(--radius-2);

    nav {
        width: 100%;
        padding-block: 8px;
        @include flex(row, center, space-between, 0, nowrap);

        .home-logo {
            img {
                height: 56px;
                width: auto;
            }
        }

        .menu-bars {
            @include flex(column, center, space-between, 0, nowrap);
            display: none;
            width: 30px;
            height: 24px;
            padding: 4px;
            background-color: $text-muted;
            border-radius: var(--radius-1);

            &.active {
                height: 30px;
                border-radius: var(--radius-round);

                .bar {
                    transform-origin: center center;

                    &:nth-child(1) {
                        transform: translateY(9.5px) rotate(45deg);
                    }

                    &:nth-child(2) {
                        visibility: hidden;
                        transition: none;
                    }

                    &:nth-child(3) {
                        transform: translateY(-9.5px) rotate(-45deg);
                    }
                }
            }

            .bar {
                display: block;
                width: 100%;
                height: 3px;
                border-radius: 2px;
                transition: all 0.3s var(--ease-3);
                background-color: $color-primary-dark;
            }
        }

        .nav__links {
            ul {
                @include flex(row, center, center, 2em, nowrap);
                padding: 12px;
                backdrop-filter: blur(24px);
                border-radius: var(--radius-2);


                li {
                    a {
                        font-size: var(--font-size-2);
                        font-weight: 500;
                        color: $text-muted;

                        &.router-link-active {
                            color: #EB5160;
                            font-weight: bold;
                        }


                        &:not(.cta) {
                            &::after {
                                content: '';
                                display: block;
                                width: 0;
                                height: 2px;
                                background-color: $text-muted;
                                transition: all .3s var(--ease-3);
                            }

                            &:hover {
                                &::after {
                                    width: 100%;
                                }
                            }

                            &.contrastColor {
                                color: $text-secondary;

                                &.router-link-active {
                                    color: #EB5160;
                                    font-weight: bold;
                                }
                            }
                        }

                        &.cta {
                            color: $text-muted;
                            padding: 8px 24px;
                            background-color: $color-primary-dark;
                            border-radius: var(--radius-2);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 630px) {
    .header {
        top: 10px;

        .nav {
            .menu-bars {
                display: flex;
            }

            .nav__links {
                position: absolute;
                width: 70dvw;
                background-color: $color-primary-lighter;
                box-shadow: var(--shadow-2);
                top: 0;
                left: 0;
                padding: 1em;
                height: 100dvh;
                @include flex(column, center, center, 2em, nowrap);
                transform: translateX(-100dvw);
                transition: all .3s var(--ease-3);

                &.offset {
                    top: -10px;
                    left: -15px;
                }

                &.active {
                    transform: translateX(0);
                }


                ul {
                    @include flex(column, center, center, 2em, nowrap);
                    width: 100%;
                    padding: 0;
                    border-radius: 0;
                    backdrop-filter: none;

                    li {
                        width: 100%;

                        a {
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            color: $text-secondary;
                        }
                    }
                }
            }
        }
    }
}
</style>