export const countriesInfo = [
  {
    name: "United States",
    id: "US",
    details: [
      {
        section: "Why Choose the USA?",
        content: [
          "Academic Excellence: The USA is home to some of the world’s top-ranked universities, offering high-quality education and cutting-edge research opportunities.",
          "Global Recognition: Degrees from American institutions are highly regarded worldwide, opening doors to career opportunities in various industries.",
          "Cultural Diversity: With students from every corner of the world, the USA offers a rich multicultural experience that broadens perspectives and fosters global connections.",
          "Technological Advancement: American universities are at the forefront of technological innovation, equipping students with skills for the future.",
          "Flexibility in Programs: The USA offers a wide range of programs and the flexibility to tailor your coursework to match your interests and career goals.",
        ],
      },
      {
        section: "System of Education in the USA",
        content: [
          "Levels of Study: Undergraduate (Associate’s and Bachelor’s degrees), graduate (Master’s), and doctoral (PhD) programs.",
          "Credit-Based System: Courses are measured in credit hours, allowing students to pace their studies according to personal preferences.",
          "Major and Minor Flexibility: Students can combine majors and minors, encouraging interdisciplinary learning.",
          "Practical Learning: Many programs emphasize hands-on learning through internships, co-op programs, and project-based coursework.",
          "Research Opportunities: Graduate and doctoral programs provide exceptional research facilities, fostering innovation.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Community Colleges: $6,000–$20,000 per year.",
          "Public Universities: $20,000–$35,000 per year for international students.",
          "Private Universities: $30,000–$60,000 or more annually.",
          "Living Costs: $10,000–$15,000 annually depending on location.",
        ],
      },
      {
        section: "Benefits of Studying in the USA",
        content: [
          "Networking Opportunities: Access to alumni networks, professional associations, and industry leaders.",
          "Personal Development: Exposure to different cultures and ideas enhances personal growth and adaptability.",
          "Post-Graduation Work Opportunities: Programs like Optional Practical Training (OPT) and H-1B visas allow students to work in the USA after graduation.",
          "Extracurricular Activities: Universities support sports, arts, and clubs that help develop skills outside academics.",
        ],
      },
      {
        section: "Top Universities in the USA",
        content: [
          "Harvard University: Known for law, medicine, and business programs.",
          "Massachusetts Institute of Technology (MIT): Renowned for engineering and technology.",
          "Stanford University: A hub for innovation and entrepreneurship.",
          "University of California, Berkeley (UC Berkeley): Leading in public research and STEM programs.",
          "University of Chicago: Acclaimed for economics and social sciences.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Fulbright Program: Fully funded scholarships for graduate studies.",
          "Hubert Humphrey Fellowship Program: For mid-career professionals.",
          "University-Specific Scholarships: Many universities, like Stanford and Columbia, offer need-based or merit-based aid.",
          "Private and Government-Funded Scholarships: Examples include AAUW Fellowships and Joint Japan/World Bank Graduate Scholarships.",
        ],
      },
      {
        section: "Intakes in the USA",
        content: [
          "Fall Intake (August–September): The most popular intake with the highest number of courses available.",
          "Spring Intake (January): A secondary option with fewer courses but ideal for those needing more preparation time.",
          "Summer Intake (May–June): Limited courses, mostly short-term programs.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Acceptance Letter: Obtain admission to a SEVP-certified university.",
          "SEVIS Fee: Pay the SEVIS (Student and Exchange Visitor Information System) fee.",
          "DS-160 Form: Complete the online application form.",
          "Visa Interview: Attend an interview at your nearest US embassy or consulate.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "On-Campus Jobs: Students on an F-1 visa can work up to 20 hours per week on campus.",
          "OPT and CPT: Optional Practical Training (OPT) allows students to work for up to 12 months post-graduation, with an extension for STEM students. Curricular Practical Training (CPT) enables work experience during studies.",
          "H-1B Visa: A pathway for skilled workers to stay and work long-term in the USA.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "TOEFL: Preferred by most US universities.",
          "IELTS: Widely accepted as an alternative to TOEFL.",
          "Duolingo English Test: Increasingly accepted by universities as a cost-effective and convenient option.",
        ],
      },
      {
        section: "Facts About Studying in the USA",
        content: [
          "The USA hosts over 5,000 higher education institutions, offering unparalleled academic options.",
          "International students contribute $44 billion annually to the US economy.",
          "The country offers over 2,000 STEM-related OPT extensions yearly.",
          "US universities emphasize extracurricular development, with more than 50% of students engaging in sports, arts, or community service.",
          "California, New York, and Texas are the most popular states for international students.",
        ],
      },
    ],
  },
  {
    name: "United Kingdom",
    id: "UK",
    details: [
      {
        section: "Why Choose the UK?",
        content: [
          "World-Class Education: The UK boasts some of the oldest and most prestigious universities, like Oxford and Cambridge.",
          "Shorter Course Duration: Undergraduate programs are typically 3 years, and Master's programs are 1 year, saving time and money.",
          "Global Recognition: UK degrees are recognized and respected worldwide for their academic rigor.",
          "Cultural Diversity: The UK is a melting pot of cultures, providing a rich and inclusive environment.",
          "Post-Study Work Opportunities: The UK offers a Graduate Route visa, allowing international students to work for up to 2 years after graduation.",
        ],
      },
      {
        section: "System of Education in the UK",
        content: [
          "Levels of Study: Undergraduate, postgraduate (Master's and PhD), and foundation courses.",
          "Focus on Independent Learning: Students are encouraged to engage in self-directed research and learning.",
          "Specialized Programs: Students typically focus on their chosen field from the start of their degree.",
          "Quality Assurance: The UK has a robust system to ensure high education standards, overseen by organizations like QAA.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Undergraduate: £10,000–£38,000 per year, depending on the course and institution.",
          "Postgraduate: £11,000–£40,000 annually, with MBA programs often at the higher end.",
          "Living Costs: £9,000–£13,000 per year, depending on location (higher in London).",
        ],
      },
      {
        section: "Benefits of Studying in the UK",
        content: [
          "Prestige: A degree from a UK university enhances your global employability.",
          "Cultural Exposure: Studying in the UK offers exposure to historic landmarks, festivals, and traditions.",
          "Access to Resources: Universities provide access to state-of-the-art libraries, research facilities, and mentorship programs.",
          "Job Market Access: Post-study work options increase employment prospects in the UK.",
        ],
      },
      {
        section: "Top Universities in the UK",
        content: [
          "University of Oxford: Known for its academic excellence and history.",
          "University of Cambridge: Renowned for research and innovation.",
          "Imperial College London: Specializes in STEM and business.",
          "London School of Economics (LSE): Focused on social sciences.",
          "University College London (UCL): Offers a diverse range of courses and research opportunities.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Chevening Scholarships: Fully funded scholarships for Master's programs.",
          "Commonwealth Scholarships: For students from Commonwealth countries.",
          "GREAT Scholarships: Offered by UK universities to students from select countries.",
          "University-Specific Scholarships: Examples include Clarendon at Oxford and Gates Cambridge.",
        ],
      },
      {
        section: "Intakes in the UK",
        content: [
          "Main Intake (September–October): Most programs start during this intake.",
          "Secondary Intake (January–February): Limited programs are available, ideal for late applicants.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "CAS (Confirmation of Acceptance for Studies): Required to apply for a student visa.",
          "Financial Proof: Show sufficient funds to cover tuition and living expenses.",
          "English Language Proficiency: Meet the university's language requirements.",
          "Tier 4 (Student Visa): Apply through the official UK Visa website.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 20 hours per week during term time.",
          "Post-Study Work: The Graduate Route allows international students to work in the UK for 2 years after graduation.",
          "Internships: Many programs offer industry placements to gain practical experience.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Widely accepted for university and visa applications.",
          "TOEFL: Accepted by most UK universities.",
          "PTE Academic: An alternative option accepted by many institutions.",
          "Duolingo English Test: Increasingly recognized for admission purposes.",
        ],
      },
      {
        section: "Facts About Studying in the UK",
        content: [
          "The UK is home to over 160 higher education institutions.",
          "London is ranked as one of the best cities for international students.",
          "Over 485,000 international students study in the UK annually.",
          "The UK offers over 100,000 courses in various fields.",
        ],
      },
    ],
  },
  {
    name: "Ireland",
    id: "IE",
    details: [
      {
        section: "Why Choose Ireland?",
        content: [
          "High-Quality Education: Ireland is home to internationally ranked universities offering innovative and career-focused programs.",
          "English-Speaking Country: Studying in Ireland allows you to study in an English-speaking environment, making it accessible for many international students.",
          "Tech Hub: Ireland hosts major global tech companies like Google, Facebook, and Apple, providing abundant job opportunities.",
          "Cultural Heritage: Ireland offers a rich cultural and historic experience with welcoming and friendly locals.",
          "Post-Study Work Opportunities: Ireland offers a generous 1-2 year post-study work visa for international graduates.",
        ],
      },
      {
        section: "System of Education in Ireland",
        content: [
          "Levels of Study: Undergraduate, postgraduate (Master’s and PhD), and diploma programs.",
          "Focus on Practical Skills: Irish universities emphasize employability through practical learning.",
          "Research Excellence: Ireland is recognized for its research output in areas like pharmaceuticals, technology, and healthcare.",
          "Flexible Pathways: Options include direct entry into degrees or foundation programs for students needing additional preparation.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Undergraduate: €9,000–€25,000 annually, depending on the course and institution.",
          "Postgraduate: €10,000–€30,000 annually, with specialized programs like MBA on the higher end.",
          "Living Costs: €8,000–€12,000 per year, depending on location (higher in Dublin).",
        ],
      },
      {
        section: "Benefits of Studying in Ireland",
        content: [
          "Growing Economy: Ireland's strong economy provides ample employment opportunities for graduates.",
          "Safe and Welcoming: Ireland is known for its safety and friendliness, making it an ideal destination for international students.",
          "Cultural Immersion: Festivals, music, and traditions make studying in Ireland an enriching experience.",
          "Industry Partnerships: Strong ties between universities and global industries ensure students are job-ready.",
        ],
      },
      {
        section: "Top Universities in Ireland",
        content: [
          "Trinity College Dublin: Known for research and humanities.",
          "University College Dublin (UCD): Renowned for business and science programs.",
          "National University of Ireland, Galway: Offers strong health sciences and engineering programs.",
          "University College Cork (UCC): Recognized for innovation and sustainability.",
          "Dublin City University (DCU): Focused on modern education and industry partnerships.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Government of Ireland Scholarships: Fully funded scholarships for outstanding international students.",
          "University-Specific Scholarships: Examples include UCD Global Scholarships and Trinity International Awards.",
          "Postgraduate Research Scholarships: Funded by Science Foundation Ireland and other organizations.",
          "External Scholarships: Examples include Erasmus+ and Fulbright Awards for US students.",
        ],
      },
      {
        section: "Intakes in Ireland",
        content: [
          "Main Intake (September): Most courses begin during this intake.",
          "Secondary Intake (January): Limited programs are available for January starters.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Acceptance Letter: Proof of admission from a recognized Irish institution.",
          "Financial Proof: Demonstrate funds to cover tuition and living expenses.",
          "Health Insurance: Required for all non-EU students.",
          "Study Visa Application: Apply through the Irish Naturalisation and Immigration Service (INIS).",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 20 hours per week during term time and 40 hours during holidays.",
          "Post-Study Work: Graduates can avail of a 1-2 year stay-back option to work in Ireland.",
          "Internships: Many programs include internships with leading global companies.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Widely accepted by Irish institutions.",
          "TOEFL: Another preferred option for English proficiency.",
          "PTE Academic: Accepted by many universities.",
          "Duolingo English Test: Increasingly recognized for admissions.",
        ],
      },
      {
        section: "Facts About Studying in Ireland",
        content: [
          "Ireland is ranked among the top 10 most innovative countries globally.",
          "Over 35,000 international students study in Ireland annually.",
          "It is the only English-speaking country in the Eurozone.",
          "Dublin is home to the European headquarters of many leading tech companies.",
        ],
      },
    ],
  },
  {
    name: "Canada",
    id: "CA",
    details: [
      {
        section: "Why Choose Canada?",
        content: [
          "High-Quality Education: Canadian universities consistently rank among the best globally, offering world-class programs.",
          "Affordable Tuition: Tuition fees in Canada are relatively lower than in other top destinations like the USA and UK.",
          "Post-Graduation Work Permit (PGWP): Offers international graduates up to 3 years of work opportunities in Canada.",
          "Diverse Environment: Canada is known for its multicultural society, welcoming students from all over the world.",
          "Safety and Quality of Life: Canada is ranked as one of the safest countries, with an excellent quality of life.",
        ],
      },
      {
        section: "System of Education in Canada",
        content: [
          "Levels of Study: Undergraduate, postgraduate (Master’s and PhD), diplomas, and certificates.",
          "Public and Private Institutions: Most Canadian universities are publicly funded, ensuring high-quality education.",
          "Co-op Programs: Many institutions offer co-op (cooperative education) options, combining classroom study with work experience.",
          "Research Opportunities: Canada is a global leader in research, especially in technology, healthcare, and environmental studies.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Undergraduate: CAD 13,000–CAD 35,000 annually, depending on the program and institution.",
          "Postgraduate: CAD 17,000–CAD 40,000 annually, with MBA programs being more expensive.",
          "Living Costs: CAD 10,000–CAD 15,000 per year, varying by location.",
        ],
      },
      {
        section: "Benefits of Studying in Canada",
        content: [
          "Immigration Pathways: Canada's immigration-friendly policies make it easier for students to transition to permanent residency.",
          "World-Class Research: Universities emphasize research, offering global exposure and advanced facilities.",
          "Multilingual Society: Opportunity to learn or improve French and English skills.",
          "Natural Beauty: Canada is known for its stunning landscapes, from mountains to lakes, offering a great outdoor experience.",
        ],
      },
      {
        section: "Top Universities in Canada",
        content: [
          "University of Toronto (UofT): Known for research and innovation.",
          "University of British Columbia (UBC): Renowned for its diverse programs and scenic campus.",
          "McGill University: Offers high-quality programs, especially in healthcare and law.",
          "University of Alberta: Known for engineering and business programs.",
          "University of Waterloo: A leader in co-op programs and STEM fields.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Vanier Canada Graduate Scholarships: For exceptional postgraduate students.",
          "Banting Postdoctoral Fellowships: Focused on research excellence.",
          "University-Specific Scholarships: Examples include UBC International Leader of Tomorrow and University of Toronto’s Lester B. Pearson Scholarship.",
          "Provincial Scholarships: Offered by provincial governments such as Ontario and British Columbia.",
        ],
      },
      {
        section: "Intakes in Canada",
        content: [
          "Fall Intake (September): The main intake with the widest range of programs.",
          "Winter Intake (January): A secondary intake, but with fewer program options.",
          "Summer Intake (May): Available for select courses, primarily short programs or diplomas.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Letter of Acceptance: Proof of admission from a Canadian Designated Learning Institution (DLI).",
          "Proof of Funds: Demonstrate sufficient funds to cover tuition and living expenses.",
          "Study Permit: Apply online or through the Canadian Visa Application Centre (VAC).",
          "Biometrics: Required for most applicants as part of the visa process.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 20 hours per week during term time and full-time during holidays.",
          "Co-op Programs: Work placements integrated into academic programs.",
          "Post-Graduation Work Permit (PGWP): Up to 3 years of work authorization after graduation.",
          "Immigration Opportunities: Many provinces offer pathways to permanent residency for graduates.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: The most commonly accepted test for Canadian institutions.",
          "TOEFL: Another widely accepted option for language proficiency.",
          "PTE Academic: Accepted by many universities.",
          "CAEL (Canadian Academic English Language): Specifically designed for Canadian education.",
        ],
      },
      {
        section: "Facts About Studying in Canada",
        content: [
          "Canada is the third most popular destination for international students.",
          "Over 640,000 international students studied in Canada in 2022.",
          "Canada offers bilingual education in English and French.",
          "The country consistently ranks as one of the best places to live globally.",
        ],
      },
    ],
  },
  {
    name: "Australia",
    id: "AU",
    details: [
      {
        section: "Why Choose Australia?",
        content: [
          "World-Class Education: Australia hosts several globally ranked universities known for their innovation and quality education.",
          "Cultural Diversity: With students from all over the world, Australia offers a truly multicultural experience.",
          "Post-Study Work Opportunities: Generous post-study work visas allow graduates to gain international work experience.",
          "Strong Focus on Research: Australia is a leader in fields like engineering, medicine, environmental science, and technology.",
          "High Quality of Life: Cities like Melbourne, Sydney, and Brisbane rank among the most livable in the world.",
        ],
      },
      {
        section: "System of Education in Australia",
        content: [
          "Levels of Study: Undergraduate, postgraduate (Master’s and PhD), vocational education and training (VET), and pathway programs.",
          "Flexible Learning: Institutions offer various modes of study, including online and blended learning.",
          "Innovative Teaching: Focus on practical learning, critical thinking, and research.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Undergraduate: AUD 20,000–AUD 45,000 annually, depending on the course.",
          "Postgraduate: AUD 22,000–AUD 50,000 annually, with MBA programs being more expensive.",
          "Living Costs: AUD 21,000–AUD 30,000 per year, depending on the city.",
        ],
      },
      {
        section: "Benefits of Studying in Australia",
        content: [
          "Internationally Recognized Degrees: Australian qualifications are highly regarded worldwide.",
          "Vibrant Student Life: A wide range of clubs, activities, and events cater to student interests.",
          "Work While Studying: Students can work part-time, gaining experience and offsetting living costs.",
          "Stunning Natural Beauty: Access to beaches, rainforests, and wildlife enhances the experience of living in Australia.",
        ],
      },
      {
        section: "Top Universities in Australia",
        content: [
          "University of Melbourne: Known for its strong emphasis on research and arts.",
          "Australian National University (ANU): A leader in social sciences and engineering.",
          "University of Sydney: Offers diverse programs and a stunning campus.",
          "University of Queensland (UQ): Renowned for biological and environmental sciences.",
          "University of New South Wales (UNSW): Specializes in business and engineering programs.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Australia Awards: Fully funded scholarships for students from eligible countries.",
          "Destination Australia Scholarships: Supports regional campus studies.",
          "University-Specific Scholarships: Examples include Monash International Merit Awards and UQ Global Scholarships.",
          "Research Training Program (RTP): Funded by the government for postgraduate research students.",
        ],
      },
      {
        section: "Intakes in Australia",
        content: [
          "Main Intakes (February and July): Most programs commence during these intakes.",
          "Summer Intake (November): Limited programs are available for this intake.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Confirmation of Enrolment (CoE): Proof of admission from a registered institution.",
          "Financial Proof: Show funds to cover tuition, living expenses, and travel.",
          "Overseas Student Health Cover (OSHC): Mandatory health insurance for international students.",
          "Genuine Temporary Entrant (GTE): Statement to demonstrate intent to study and return.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 48 hours every two weeks during term time and unlimited hours during holidays.",
          "Post-Study Work Visas: Graduates can stay and work for 2–4 years, depending on their qualification.",
          "Internships: Many institutions integrate internships into their programs.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Widely accepted by Australian universities and visa applications.",
          "TOEFL: Another preferred option for language proficiency.",
          "PTE Academic: Increasingly accepted across institutions.",
          "Cambridge English: Recognized by many universities and colleges.",
        ],
      },
      {
        section: "Facts About Studying in Australia",
        content: [
          "Australia hosts more than 500,000 international students annually.",
          "It has seven universities ranked in the top 100 globally.",
          "Australia is the third most popular destination for international students after the USA and UK.",
          "The country invests heavily in research and innovation, contributing to its global reputation.",
        ],
      },
    ],
  },
  {
    name: "France",
    id: "FR",
    details: [
      {
        section: "Why Choose France?",
        content: [
          "Rich Cultural Heritage: France offers a blend of art, history, and modern innovation, making it an exciting place to study.",
          "Globally Recognized Education: French universities and grandes écoles are renowned for their excellence, especially in business, fashion, and engineering.",
          "Affordable Tuition: Public universities in France offer low-cost education, even for international students.",
          "Diverse Programs: Wide range of programs taught in both French and English.",
          "Gateway to Europe: Centrally located in Europe, France provides opportunities to explore nearby countries.",
        ],
      },
      {
        section: "System of Education in France",
        content: [
          "Types of Institutions: Universities, specialized schools, and grandes écoles (prestigious higher education institutions).",
          "Bologna Process: Degrees follow the LMD system (Licence, Master, Doctorate), compatible with European standards.",
          "Focus on Research: Many institutions emphasize research, particularly in STEM fields and humanities.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Public Universities: €170–€650 annually for undergraduate programs; postgraduate programs range from €250–€450.",
          "Private Institutions: €3,000–€10,000 annually, depending on the course.",
          "Living Costs: €10,000–€15,000 per year, with Paris being on the higher end.",
        ],
      },
      {
        section: "Benefits of Studying in France",
        content: [
          "Scholarship Opportunities: The French government and universities provide numerous scholarships for international students.",
          "High-Quality Lifestyle: France offers excellent healthcare, public transport, and recreational facilities.",
          "Multilingual Learning: Many institutions offer programs in English while also giving students the chance to learn French.",
          "Diverse Student Community: France welcomes over 300,000 international students annually.",
        ],
      },
      {
        section: "Top Universities in France",
        content: [
          "Sorbonne University: Known for arts and humanities, with a strong emphasis on research.",
          "École Polytechnique: A leading institution in science and engineering.",
          "INSEAD: World-renowned for its MBA and business programs.",
          "Sciences Po: Specializes in social sciences, international relations, and political science.",
          "Université PSL (Paris Sciences et Lettres): A hub for interdisciplinary research and innovation.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Eiffel Excellence Scholarship: Offered by the French Ministry for Europe and Foreign Affairs.",
          "Campus France Scholarships: Includes programs like Charpak and MOPGA (Make Our Planet Great Again).",
          "Regional Scholarships: Many French regions provide funding for international students.",
          "University-Specific Scholarships: Examples include École Normale Supérieure (ENS) scholarships.",
        ],
      },
      {
        section: "Intakes in France",
        content: [
          "Fall Intake (September): The primary intake for most programs.",
          "Spring Intake (January/February): Limited programs are available in this session.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Acceptance Letter: Proof of admission to a French institution.",
          "Proof of Funds: Evidence of at least €615 per month for living expenses.",
          "Student Visa (VLS-TS): Long-term visa that doubles as a residence permit.",
          "Health Insurance: Mandatory for all international students.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 20 hours per week during the academic year.",
          "Post-Graduation Opportunities: Stay-back options include the Autorisation Provisoire de Séjour (APS) for job search or entrepreneurship.",
          "Internships: Integrated into many academic programs, often leading to employment opportunities.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Accepted for English-taught programs.",
          "TOEFL: Widely recognized by universities.",
          "PTE Academic: Increasingly accepted by French institutions.",
          "French Proficiency: For French-taught programs, tests like DELF or DALF may be required.",
        ],
      },
      {
        section: "Facts About Studying in France",
        content: [
          "France is the fourth most popular study destination globally.",
          "Home to over 3,500 higher education institutions, including specialized schools.",
          "The French government spends about €14,000 per student annually to subsidize education.",
          "France is a global leader in luxury goods, fashion, and gastronomy, providing unique industry exposure.",
        ],
      },
    ],
  },
  {
    name: "Germany",
    id: "GE",
    details: [
      {
        section: "Why Choose Germany?",
        content: [
          "World-Class Education: Germany is known for its technical excellence and cutting-edge research facilities.",
          "Tuition-Free Universities: Most public universities offer free or minimal tuition fees for both domestic and international students.",
          "Strong Economy: Germany has a robust economy with opportunities in engineering, IT, healthcare, and more.",
          "Globally Recognized Degrees: Degrees from German institutions are highly valued worldwide.",
          "Central Location: Located in the heart of Europe, Germany is a gateway to exploring neighboring countries.",
        ],
      },
      {
        section: "System of Education in Germany",
        content: [
          "Types of Institutions: Public universities, universities of applied sciences (Fachhochschulen), and private institutions.",
          "Focus on Practical Learning: Many programs emphasize internships and industry exposure.",
          "Bologna Process: Compatible with the European LMD (Licence, Master, Doctorate) structure.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Public Universities: Free or minimal semester fees (typically €150–€350 per semester).",
          "Private Institutions: €10,000–€20,000 annually, depending on the course.",
          "Living Costs: €10,000–€12,000 per year, including accommodation, food, and other expenses.",
        ],
      },
      {
        section: "Benefits of Studying in Germany",
        content: [
          "Research Opportunities: Germany is a leader in engineering, automotive technology, and natural sciences.",
          "Strong Industry Connections: Collaboration with leading companies provides valuable exposure.",
          "Cultural Experience: A rich history and diverse culture make Germany an exciting destination.",
          "Work While Studying: Students can work part-time to gain experience and support their living costs.",
        ],
      },
      {
        section: "Top Universities in Germany",
        content: [
          "Technical University of Munich (TUM): Known for engineering and technology programs.",
          "Ludwig Maximilian University of Munich (LMU): Renowned for humanities and social sciences.",
          "Heidelberg University: One of the oldest universities, specializing in medicine and natural sciences.",
          "RWTH Aachen University: A leader in technical and engineering fields.",
          "University of Freiburg: Known for environmental and life sciences.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "DAAD Scholarships: Offered by the German Academic Exchange Service for international students.",
          "Deutschlandstipendium: Merit-based funding for talented students.",
          "Erasmus+: Supports exchange programs and scholarships for EU and non-EU students.",
          "University-Specific Scholarships: Various institutions provide funding for international students.",
        ],
      },
      {
        section: "Intakes in Germany",
        content: [
          "Winter Semester (October): The main intake for most programs.",
          "Summer Semester (April): Limited courses are available in this intake.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "University Admission Letter: Proof of admission to a German institution.",
          "Blocked Account: Show €11,208 per year as financial proof (as of 2024).",
          "Health Insurance: Mandatory for all international students.",
          "Residence Permit: Apply upon arrival for stays longer than 90 days.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work 120 full days or 240 half days per year.",
          "Post-Study Work Visa: Graduates can stay for 18 months to find a job related to their field.",
          "Internships: Integrated into many degree programs, offering valuable industry exposure.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Accepted for English-taught programs.",
          "TOEFL: Widely recognized for admissions.",
          "PTE Academic: Increasingly accepted by institutions.",
          "German Proficiency: Tests like TestDaF or DSH are required for German-taught programs.",
        ],
      },
      {
        section: "Facts About Studying in Germany",
        content: [
          "Germany hosts over 400,000 international students annually.",
          "It has more than 100 public universities with free tuition for most programs.",
          "Germany is a leader in renewable energy research and engineering innovation.",
          "The country has a strong focus on apprenticeship and practical learning.",
        ],
      },
    ],
  },
  {
    name: "New Zealand",
    id: "NZ",
    details: [
      {
        section: "Why Choose New Zealand?",
        content: [
          "World-Class Education: New Zealand’s universities consistently rank high in global rankings.",
          "Safety and Quality of Life: Known for being one of the safest and most peaceful countries in the world.",
          "Innovative Teaching: A focus on research-driven and practical learning.",
          "Diverse Natural Beauty: An outdoor haven with stunning landscapes, making it a unique study destination.",
          "Post-Study Opportunities: Generous work and residency options for international graduates.",
        ],
      },
      {
        section: "System of Education in New Zealand",
        content: [
          "Types of Institutions: Universities, institutes of technology, and private training establishments.",
          "Globally Recognized Degrees: The New Zealand Qualifications Framework (NZQF) ensures degree comparability worldwide.",
          "Focus on Research and Practical Learning: Encourages critical thinking and innovation across all levels.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Undergraduate Programs: NZD 20,000–NZD 35,000 annually.",
          "Postgraduate Programs: NZD 22,000–NZD 40,000 annually.",
          "Living Costs: NZD 15,000–NZD 18,000 per year for accommodation, food, and other expenses.",
        ],
      },
      {
        section: "Benefits of Studying in New Zealand",
        content: [
          "Welcoming Environment: New Zealand is known for its friendly and multicultural society.",
          "Student Support: Universities offer excellent student support services for international students.",
          "Strong Job Prospects: Growing industries in agriculture, IT, and healthcare provide job opportunities.",
          "Adventure Opportunities: Ideal for those who love adventure sports and exploring nature.",
        ],
      },
      {
        section: "Top Universities in New Zealand",
        content: [
          "University of Auckland: The highest-ranked university in New Zealand, offering a variety of programs.",
          "University of Otago: Known for health sciences and a vibrant student community.",
          "Victoria University of Wellington: Renowned for its arts and humanities programs.",
          "University of Canterbury: A leader in engineering and natural sciences.",
          "Massey University: Known for veterinary science, business, and creative arts.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "New Zealand Excellence Awards: For Indian and Southeast Asian students.",
          "New Zealand Development Scholarships: Funded by the New Zealand government for students from developing countries.",
          "University-Specific Scholarships: Many universities offer scholarships for academic excellence.",
        ],
      },
      {
        section: "Intakes in New Zealand",
        content: [
          "February Intake: The main intake for most programs.",
          "July Intake: Secondary intake with a limited selection of courses.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Offer of Place: Proof of admission to a New Zealand educational institution.",
          "Proof of Funds: Evidence of NZD 15,000 per year for living expenses.",
          "Health and Character Certificates: Medical clearance and a police certificate.",
          "Student Visa: Required for stays longer than three months.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 20 hours per week during the academic year.",
          "Post-Study Work Visa: Graduates can apply for a post-study work visa for up to three years.",
          "Internships: Many courses include practical work placements.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Required by most universities, with a minimum score of 6.0–6.5.",
          "TOEFL: Accepted as an alternative to IELTS.",
          "PTE Academic: Increasingly recognized by universities and institutions.",
        ],
      },
      {
        section: "Facts About Studying in New Zealand",
        content: [
          "New Zealand has eight universities, all ranked in the QS World University Rankings.",
          "It was the first country to adopt the Code of Practice for the Pastoral Care of International Students.",
          "The country is known for its contributions to environmental sustainability and clean energy.",
          "New Zealand is home to over 120,000 international students from around the world.",
        ],
      },
    ],
  },
  {
    name: "Cyprus",
    id: "CY",
    details: [
      {
        section: "Why Choose Cyprus?",
        content: [
          "Affordable Education: Cyprus offers high-quality education at a relatively lower cost compared to other European countries.",
          "Strategic Location: Positioned at the crossroads of Europe, Asia, and Africa, Cyprus is a vibrant cultural hub.",
          "English-Taught Programs: Most universities offer programs in English, making it accessible for international students.",
          "Safe and Peaceful: Cyprus is one of the safest countries in Europe, with a low crime rate.",
          "Modern Infrastructure: Universities in Cyprus boast state-of-the-art facilities and a conducive learning environment.",
        ],
      },
      {
        section: "System of Education in Cyprus",
        content: [
          "Types of Institutions: Universities, higher education institutes, and private colleges.",
          "Emphasis on Innovation: Programs focus on practical knowledge and industry connections.",
          "Quality Assurance: Higher education institutions in Cyprus adhere to European standards of quality.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "Undergraduate Programs: €3,500–€8,000 annually.",
          "Postgraduate Programs: €5,000–€10,000 annually.",
          "Living Costs: €5,000–€8,000 per year for accommodation, food, and other essentials.",
        ],
      },
      {
        section: "Benefits of Studying in Cyprus",
        content: [
          "Multicultural Environment: Cyprus is home to students from over 50 different countries.",
          "EU Residency Advantage: As part of the EU, Cyprus offers easy mobility within Europe.",
          "Sunny Mediterranean Climate: The beautiful weather and scenic landscapes provide a perfect backdrop for study and leisure.",
          "Flexible Study Options: Universities offer flexible study schedules and part-time opportunities.",
        ],
      },
      {
        section: "Top Universities in Cyprus",
        content: [
          "University of Cyprus: A leading institution known for research and academic excellence.",
          "Cyprus University of Technology: Focuses on applied sciences and technology.",
          "European University Cyprus: Offers diverse programs and a global perspective.",
          "Frederick University: Renowned for engineering and business studies.",
          "Neapolis University Pafos: Known for innovation and entrepreneurship programs.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Government Scholarships: Offered to international students for undergraduate and postgraduate programs.",
          "University-Specific Scholarships: Many institutions provide merit-based and need-based scholarships.",
          "Private Funding: Several organizations and foundations in Cyprus offer financial aid.",
        ],
      },
      {
        section: "Intakes in Cyprus",
        content: [
          "Fall Semester (September): The primary intake for most programs.",
          "Spring Semester (February): A secondary intake with a limited selection of courses.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "University Admission Letter: Proof of enrollment at a Cypriot institution.",
          "Financial Proof: Evidence of sufficient funds to cover tuition and living expenses.",
          "Medical Certificate: Health clearance, including TB and HIV tests.",
          "Student Visa: Issued by the Cypriot embassy before arrival.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: Students can work up to 20 hours per week during the academic term.",
          "Post-Study Opportunities: Limited options, but graduates can explore pathways within the EU.",
          "Internships: Available through university programs or external applications.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Required for English-taught programs, with a minimum score of 5.5–6.0.",
          "TOEFL: Accepted as an alternative to IELTS.",
          "No Test: Some universities allow alternative proof, such as previous education in English.",
        ],
      },
      {
        section: "Facts About Studying in Cyprus",
        content: [
          "Cyprus is home to over 25,000 international students annually.",
          "It is known for its strong hospitality and tourism programs.",
          "The country enjoys over 300 days of sunshine annually.",
          "Cyprus has a high literacy rate of over 98%.",
        ],
      },
    ],
  },
  {
    name: "Netherlands",
    id: "NL",
    details: [
      {
        section: "Why Choose Netherlands?",
        content: [
          "Globally Recognized Education: Dutch universities are renowned for their high-quality programs.",
          "Affordable Tuition: Lower tuition fees compared to other English-speaking countries.",
          "Innovative Teaching: Programs focus on problem-based and collaborative learning.",
          "English-Taught Programs: Over 2,100 programs are available in English.",
          "Central Location in Europe: Easy travel to neighboring European countries.",
        ],
      },
      {
        section: "System of Education in Netherlands",
        content: [
          "Types of Institutions: Research universities, universities of applied sciences, and institutes for international education.",
          "Bologna System: Degrees are structured as bachelor's, master's, and PhDs, adhering to European standards.",
          "Practical Learning: Emphasis on work placements and real-world problem-solving.",
        ],
      },
      {
        section: "Average Tuition Costs",
        content: [
          "EU/EEA Students: €2,500 annually for most bachelor's programs.",
          "Non-EU Students: €8,000–€20,000 annually depending on the program.",
          "Living Costs: €800–€1,200 per month for accommodation, food, and other expenses.",
        ],
      },
      {
        section: "Benefits of Studying in Netherlands",
        content: [
          "Cultural Diversity: The Netherlands welcomes students from over 160 countries.",
          "Strong Job Market: Opportunities in industries like engineering, IT, and finance.",
          "English Fluency: Over 90% of the population speaks English.",
          "Sustainability Focus: Known for green initiatives and environmental awareness.",
        ],
      },
      {
        section: "Top Universities in Netherlands",
        content: [
          "Delft University of Technology: A leader in engineering and technology.",
          "University of Amsterdam: Ranked among the top universities for research and liberal arts.",
          "Leiden University: Known for law and humanities programs.",
          "Eindhoven University of Technology: Focused on innovation in science and technology.",
          "Maastricht University: Offers problem-based learning and an international focus.",
        ],
      },
      {
        section: "Scholarships for International Students",
        content: [
          "Holland Scholarship: Available for non-EU students to pursue bachelor's or master's programs.",
          "Erasmus Mundus: For students applying to joint master's programs in Europe.",
          "University-Specific Scholarships: Many Dutch institutions provide merit-based scholarships.",
        ],
      },
      {
        section: "Intakes in Netherlands",
        content: [
          "September Intake: The main intake for most programs.",
          "February Intake: Limited availability for certain programs.",
        ],
      },
      {
        section: "Visa Requirements",
        content: [
          "Admission Proof: Letter of acceptance from a Dutch institution.",
          "MVV Visa: A provisional residence permit for long-term stays.",
          "Proof of Funds: At least €11,000 annually for living expenses.",
          "Health Insurance: Mandatory for all international students.",
        ],
      },
      {
        section: "Work Opportunities",
        content: [
          "Part-Time Work: EU students can work without restrictions; non-EU students are limited to 16 hours per week.",
          "Post-Study Visa: Graduates can apply for a one-year orientation visa to search for jobs.",
          "Internships: Often included as part of academic programs.",
        ],
      },
      {
        section: "English Proficiency Tests",
        content: [
          "IELTS: Minimum score of 6.0 for most programs.",
          "TOEFL: Accepted with a minimum score of 80–90.",
          "PTE Academic: Increasingly accepted by universities.",
        ],
      },
      {
        section: "Facts About Studying in Netherlands",
        content: [
          "The Netherlands was the first non-English-speaking country to offer English-taught programs.",
          "It is ranked among the top 10 happiest countries globally.",
          "The Dutch education system dates back to the 16th century.",
          "The country is home to 13 globally ranked research universities.",
        ],
      },
    ],
  },
];
